import React from 'react'
import dado1 from "../img/productos/DadosyPuntas/1.-Dado de impacto de 26 mm, 6 puntas, cuadro 1-2, Truper.jpg"
import dado2 from "../img/productos/DadosyPuntas/2.-Dado de impacto de 27 mm, 6 puntas, cuadro 1-2, Truper.jpg"
import dado3 from "../img/productos/DadosyPuntas/3.-Dado largo impacto de 22 mm, 6 puntas, cuadro 12, Truper.jpg"
import dado4 from "../img/productos/DadosyPuntas/4.-Dado largo impacto de 23 mm, 6 puntas, cuadro 12, Truper.jpg"
import dado5 from "../img/productos/DadosyPuntas/5.-Dado largo impacto de 24 mm, 6 puntas, cuadro 12, Truper.jpg"
import dado6 from "../img/productos/DadosyPuntas/6.-Dado largo impacto de 25 mm, 6 puntas, cuadro 12, Truper.jpg"
import dado7 from "../img/productos/DadosyPuntas/7.-Dado largo impacto de 26 mm, 6 puntas, cuadro 12, Truper.jpg"
import dado8 from "../img/productos/DadosyPuntas/8.-Dado largo impacto de 27 mm, 6 puntas, cuadro 12, Truper.jpg"
import dado9 from "../img/productos/DadosyPuntas/9.-Dado largo de impacto de 1-3-16, 6 puntas, cuadro 3-4.jpg"
import dado10 from "../img/productos/DadosyPuntas/10.-Dado largo de 5.5 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado11 from "../img/productos/DadosyPuntas/11.-Dado largo de 14, 6 puntas, cuadro 38, Truper.jpg"
import dado12 from "../img/productos/DadosyPuntas/12.-Dado largo de 516, 6 puntas, cuadro 38, Truper.jpg"
import dado13 from "../img/productos/DadosyPuntas/13.-Dado largo de 6 mm, 6 puntas, cuadro 38, Truper.jpg"
import dado14 from "../img/productos/DadosyPuntas/14.-Dado largo de 7 mm, 6 puntas, cuadro 38, Truper.jpg"
import dado15 from "../img/productos/DadosyPuntas/15.-Dado largo de 8 mm, 6 puntas, cuadro 38, Truper.jpg"
import dado16 from "../img/productos/DadosyPuntas/16.-Dado largo de 9 mm, 6 puntas, cuadro 38, Truper.jpg"
import dado17 from "../img/productos/DadosyPuntas/17.-Dado largo de 4 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado18 from "../img/productos/DadosyPuntas/18.-Dado largo de 20 mm, 6 puntas, cuadro 38, Truper.jpg"
import dado19 from "../img/productos/DadosyPuntas/19.-Dado largo de 1-116, 6 puntas, cuadro 12, Truper.jpg"
import dado20 from "../img/productos/DadosyPuntas/20.-Dado de 14 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado21 from "../img/productos/DadosyPuntas/21.-Dado de 916, 6 puntas, cuadro 14, Truper.jpg"
import dado22 from "../img/productos/DadosyPuntas/22.-Dado de 6 mm, 12 puntas, cuadro 38, Truper.jpg"
import dado23 from "../img/productos/DadosyPuntas/23.-Dado de 5.5 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado24 from "../img/productos/DadosyPuntas/24.-Dado largo de 1-18, 6 puntas, cuadro 12, Truper.jpg"
import dado25 from "../img/productos/DadosyPuntas/25.-Dado largo de 1-316, 6 puntas, cuadro 12, Truper.jpg"
import dado26 from "../img/productos/DadosyPuntas/26.-Dado largo de 22 mm, 6 puntas, cuadro 12, Truper.jpg"
import dado27 from "../img/productos/DadosyPuntas/27.-Dado largo de 23 mm, 6 puntas, cuadro 12, Truper.jpg"
import dado28 from "../img/productos/DadosyPuntas/28.-Dado largo de 20 mm, 6 puntas, cuadro 12, Truper.jpg"
import dado29 from "../img/productos/DadosyPuntas/29.-Dado largo de 1-14, 6 puntas, cuadro 12, Truper.jpg"
import dado30 from "../img/productos/DadosyPuntas/30.-Dado largo de 1-316 12 puntas, cuadro 12, Truper.jpg"
import dado31 from "../img/productos/DadosyPuntas/31.-Dado de 26 mm, 6 puntas, cuadro 12, Truper.jpg"
import dado32 from "../img/productos/DadosyPuntas/32.-Dado de 27 mm, 6 puntas, cuadro 12, Truper.jpg"
import dado33 from "../img/productos/DadosyPuntas/33.-Dado de 4 mm, 12 puntas, cuadro 14, Truper.jpg"
import dado34 from "../img/productos/DadosyPuntas/34.-Dado de 12 mm, 12 puntas, cuadro 14, Truper.jpg"
import dado35 from "../img/productos/DadosyPuntas/35.-Dado de 13 mm, 12 puntas, cuadro 14, Truper.jpg"
import dado36 from "../img/productos/DadosyPuntas/36.-Dado de 14 mm, 12 puntas, cuadro 14, Truper.jpg"
import dado37 from "../img/productos/DadosyPuntas/37.-Dado de 5 mm, 12 puntas, cuadro 14, Truper.jpg"
import dado38 from "../img/productos/DadosyPuntas/38.-Dado de 5.5 mm, 12 puntas, cuadro 14, Truper.jpg"
import dado39 from "../img/productos/DadosyPuntas/39.-Dado de 6 mm, 12 puntas, cuadro 14, Truper.jpg"
import dado40 from "../img/productos/DadosyPuntas/40.-Dado de 7 mm, 12 puntas, cuadro 14, Truper.jpg"
import dado41 from "../img/productos/DadosyPuntas/41.-Dado de 8 mm, 12 puntas, cuadro 14, Truper.jpg"
import dado42 from "../img/productos/DadosyPuntas/42.-Dado de 9 mm, 12 puntas, cuadro 14, Truper.jpg"
import dado43 from "../img/productos/DadosyPuntas/43.-Dado de 10 mm, 12 puntas, cuadro 14, Truper.jpg"
import dado44 from "../img/productos/DadosyPuntas/44.-Dado de 11 mm, 12 puntas, cuadro 14, Truper.jpg"
import dado45 from "../img/productos/DadosyPuntas/45.-Dado de 5-32, 12 puntas, cuadro 1-4, Truper.jpg"
import dado46 from "../img/productos/DadosyPuntas/46.-Dado de 12, 12 puntas, cuadro 14, Truper.jpg"
import dado47 from "../img/productos/DadosyPuntas/47.-Dado de 916, 12 puntas, cuadro 14, Truper.jpg"
import dado49 from "../img/productos/DadosyPuntas/49.-Dado de 316, 12 puntas, cuadro 14, Truper.jpg"
import dado50 from "../img/productos/DadosyPuntas/50.-Dado de 732, 12 puntas, cuadro 14, Truper.jpg"
import dado51 from "../img/productos/DadosyPuntas/51.-Dado de 14, 12 puntas, cuadro 14, Truper.jpg"
import dado52 from "../img/productos/DadosyPuntas/52.-Dado de 932, 12 puntas, cuadro 14, Truper.jpg"
import dado53 from "../img/productos/DadosyPuntas/53.-Dado de 516, 12 puntas, cuadro 14, Truper.jpg"
import dado54 from "../img/productos/DadosyPuntas/54.-Dado de 1132, 12 puntas, cuadro 14, Truper.jpg"
import dado55 from "../img/productos/DadosyPuntas/55.-Dado de 38, 12 puntas, cuadro 14, Truper.jpg"
import dado56 from "../img/productos/DadosyPuntas/56.-Dado de 7-16, 12 puntas, cuadro 1-4, Truper.jpg"
import dado57 from "../img/productos/DadosyPuntas/57.-Dado de 4 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado58 from "../img/productos/DadosyPuntas/58.-Dado de 13 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado59 from "../img/productos/DadosyPuntas/59.-Dado de 5 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado60 from "../img/productos/DadosyPuntas/60.-Dado de 6 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado61 from "../img/productos/DadosyPuntas/61.-Dado de 7 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado62 from "../img/productos/DadosyPuntas/62.-Dado de 8 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado63 from "../img/productos/DadosyPuntas/63.-Dado de 9 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado64 from "../img/productos/DadosyPuntas/64.-Dado de 10 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado65 from "../img/productos/DadosyPuntas/65.-Dado de 11 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado66 from "../img/productos/DadosyPuntas/66.-Dado de 12 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado67 from "../img/productos/DadosyPuntas/67.-Dado largo de 6 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado68 from "../img/productos/DadosyPuntas/68.-Dado largo de 7 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado69 from "../img/productos/DadosyPuntas/69.-Dado largo de 8 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado70 from "../img/productos/DadosyPuntas/70.-Dado largo de 9 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado71 from "../img/productos/DadosyPuntas/71.-Dado largo de 10 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado72 from "../img/productos/DadosyPuntas/72.-Dado largo de 11 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado73 from "../img/productos/DadosyPuntas/73.-Dado largo de 12 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado74 from "../img/productos/DadosyPuntas/74.-Dado largo de 13 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado75 from "../img/productos/DadosyPuntas/75.-Dado largo de 14 mm, 6 puntas, cuadro 14, Truper.jpg"
import dado76 from "../img/productos/DadosyPuntas/76.-Dado largo de 14, 6 puntas, cuadro 14, Truper.jpg"
import dado77 from "../img/productos/DadosyPuntas/77.-Dado largo de 932, 6 puntas, cuadro 14, Truper.jpg"
import dado78 from "../img/productos/DadosyPuntas/78.-Dado largo de 516, 6 puntas, cuadro 14, Truper.jpg"
import dado79 from "../img/productos/DadosyPuntas/79.-Dado largo de 1132, 6 puntas, cuadro 14, Truper.jpg"
import dado80 from "../img/productos/DadosyPuntas/80.-Dado largo de 38, 6 puntas, cuadro 14, Truper.jpg"
import dado81 from "../img/productos/DadosyPuntas/81.-Dado largo de 716, 6 puntas, cuadro 14, Truper.jpg"
import dado82 from "../img/productos/DadosyPuntas/82.-Dado largo de 12, 6 puntas, cuadro 14, Truper.jpg"
import dado83 from "../img/productos/DadosyPuntas/83.-Dado largo de 916, 6 puntas, cuadro 14, Truper.jpg"
import dado84 from "../img/productos/DadosyPuntas/84.-Dado largo impacto prot. plást. 17 mm, 6 puntas, cuadro 12.jpg"
import dado85 from "../img/productos/DadosyPuntas/85.-Dado largo impacto prot. plást. 19 mm, 6 puntas, cuadro 12.jpg"
import dado86 from "../img/productos/DadosyPuntas/86.-Dado largo impacto prot. plást. 21 mm, 6 puntas, cuadro 12.jpg"
import dado87 from "../img/productos/DadosyPuntas/87.-Dado largo impacto prot. plást. 34, 6 puntas, cuadro 12.jpg"
import dado88 from "../img/productos/DadosyPuntas/88.-Dado largo impacto prot. plást. 1316, 6 puntas,cuadro 12.jpg"
import dado89 from "../img/productos/DadosyPuntas/89.-Dado largo impacto prot. plást. 78, 6 puntas, cuadro 12.jpg"
import dado90 from "../img/productos/DadosyPuntas/90.-Dado punta larga bristol M5, cuadro 12, Truper.jpg"
import dado91 from "../img/productos/DadosyPuntas/91.-Dado punta larga bristol M6, cuadro 12, Truper.jpg"
import dado92 from "../img/productos/DadosyPuntas/92.-Dado punta larga bristol M8, cuadro 12, Truper.jpg"
import dado93 from "../img/productos/DadosyPuntas/93.-Dado punta larga bristol M10, cuadro 12, Truper.jpg"
import dado94 from "../img/productos/DadosyPuntas/94.-Dado punta larga bristol M12, cuadro 1-2, Truper.jpg"
import dado95 from "../img/productos/DadosyPuntas/95.-Dado punta larga bristol M14, cuadro 12, Truper.jpg"
import dado96 from "../img/productos/DadosyPuntas/96.-Dado punta larga bristol M16, cuadro 12, Truper.jpg"
import dado97 from "../img/productos/DadosyPuntas/97.-Dado para termofusora de 1-14 40 mm, Foset.jpg"
import dado98 from "../img/productos/DadosyPuntas/98.-Dado para termofusora de 1-12 50 mm, Foset.jpg"
import dado99 from "../img/productos/DadosyPuntas/99.-Dado para termofusora de 2 63 mm, Foset.jpg"
import dado100 from "../img/productos/DadosyPuntas/100.-Dado para termofusora de 12 20 mm, Foset.jpg"
import dado101 from "../img/productos/DadosyPuntas/101.-Dado para termofusora de 3-4 25 mm, Foset.jpg"
import dado102 from "../img/productos/DadosyPuntas/102.-Dado para termofusora de 1 32 mm, Foset.jpg"
import dado103 from "../img/productos/DadosyPuntas/103.-Dados de punta corta, cuadro 12.jpg"
import dado104 from "../img/productos/DadosyPuntas/104.-Dado punta corta bristol M6, cuadro 1-2, Truper.jpg"
import dado105 from "../img/productos/DadosyPuntas/105.-Dado punta corta bristol M8, cuadro 1-2, Truper.jpg"
import dado106 from "../img/productos/DadosyPuntas/106.-Dado punta corta bristol M10, cuadro 1-2, Truper.jpg"
import dado107 from "../img/productos/DadosyPuntas/107.-Dado punta corta bristol M12, cuadro 1-2, Truper.jpg"
import dado108 from "../img/productos/DadosyPuntas/108.-Dado punta corta bristol M14, cuadro 1-2, Truper.jpg"
import dado109 from "../img/productos/DadosyPuntas/109.-Dado punta corta bristol M16, cuadro 1-2, Truper.jpg"
import dado110 from "../img/productos/DadosyPuntas/110.-Punta de repuesto para cautín tipo lápiz CAU-300X, Truper.jpg"
import dado111 from "../img/productos/DadosyPuntas/111.-Punta de repuesto para cautín tipo lápiz CAU-150X, Truper.jpg"
import dado112 from "../img/productos/DadosyPuntas/112.-Punta pol de latón 1-4 x 5 cm, de rosca con O-ring, Foset.jpg"
import dado113 from "../img/productos/DadosyPuntas/113.-Punta pol de latón 3-8 x 6 cm, de espiga, Foset.jpg"
import dado114 from "../img/productos/DadosyPuntas/114.-Punta pol de latón 3-8 x 12 cm, de espiga, Foset.jpg"
import dado115 from "../img/productos/DadosyPuntas/115.-Punta pol de latón 1-4 x 6 cm, de rosca, Foset.jpg"
import dado116 from "../img/productos/DadosyPuntas/116.-Cincel de punta SDS max de 16, Truper.jpg"
import dado117 from "../img/productos/DadosyPuntas/117.-Cincel de punta zanco hexagonal de 20, Truper.jpg"
import dado118 from "../img/productos/DadosyPuntas/118.-Cincel de punta SDS Plus de 10, Truper.jpg"
import dado119 from "../img/productos/DadosyPuntas/119.-Dado punta hexagonal de 3-16, cuadro 3-8, Truper.jpg"
import dado120 from "../img/productos/DadosyPuntas/120.-Dado punta hexagonal de 7-32, cuadro 3-8, Truper.jpg"
import dado121 from "../img/productos/DadosyPuntas/121.-Dado punta hexagonal de 1-4, cuadro 3-8, Truper.jpg"
import dado122 from "../img/productos/DadosyPuntas/122.-Dado punta hexagonal de 5-16, cuadro 3-8, Truper.jpg"
import dado123 from "../img/productos/DadosyPuntas/123.-Dado punta hexagonal de 3-8, cuadro 3-8, Truper.jpg"
import dado124 from "../img/productos/DadosyPuntas/124.-Dado punta hexagonal de 4 mm, cuadro 3-8, Truper.jpg"
import dado125 from "../img/productos/DadosyPuntas/125.-Dado punta hexagonal de 5 mm, cuadro 3-8, Truper.jpg"
import dado126 from "../img/productos/DadosyPuntas/126.-Dado punta hexagonal de 6 mm, cuadro 3-8, Truper.jpg"
import dado127 from "../img/productos/DadosyPuntas/127.-Dado punta hexagonal de 7 mm, cuadro 3-8, Truper.jpg"
import dado128 from "../img/productos/DadosyPuntas/128.-Dado punta hexagonal de 8 mm, cuadro 3-8, Truper.jpg"
import dado129 from "../img/productos/DadosyPuntas/129.-Dado punta hexagonal de 10 mm, cuadro 3-8, Truper.jpg"
import dado130 from "../img/productos/DadosyPuntas/130.-Dado punta torx T-15, cuadro 3-8, Truper.jpg"
import dado131 from "../img/productos/DadosyPuntas/131.-Dado punta torx T-60, cuadro 3-8, Truper.jpg"
import dado132 from "../img/productos/DadosyPuntas/132.-Dado punta torx T-20, cuadro 3-8, Truper.jpg"
import dado133 from "../img/productos/DadosyPuntas/133.-Dado punta torx T-25, cuadro 3-8, Truper.jpg"
import dado134 from "../img/productos/DadosyPuntas/134.-Dado punta torx T-27, cuadro 3-8,Truper.jpg"
import dado135 from "../img/productos/DadosyPuntas/135.-Dado punta torx T-30, cuadro 3-8, Truper.jpg"
import dado136 from "../img/productos/DadosyPuntas/136.-Dado punta torx T-40, cuadro 3-8, Truper.jpg"
import dado137 from "../img/productos/DadosyPuntas/137.-Dado punta torx T-45, cuadro 3-8, Truper.jpg"
import dado138 from "../img/productos/DadosyPuntas/138.-Dado punta torx T-50, cuadro 3-8 Truper.jpg"
import dado139 from "../img/productos/DadosyPuntas/139.-Dado punta torx T-55, cuadro 3-8, Truper.jpg"
import dado140 from "../img/productos/DadosyPuntas/140.-Barreta de punta de 1-1-4 x 175 cm, escoplo 80 mm, Truper.jpg"
import dado141 from "../img/productos/DadosyPuntas/141.-Barreta de punta de 1 x 150 cm, escoplo 72 mm, Truper.jpg"
import dado142 from "../img/productos/DadosyPuntas/142.-Barreta de punta de 1x 175 cm, escoplo 72 mm, Truper.jpg"
import dado143 from "../img/productos/DadosyPuntas/143.-Barreta de punta de 7-8 x 100 cm, escoplo 60 mm, Truper.jpg"
import dado144 from "../img/productos/DadosyPuntas/144.-Barreta de punta de 7-8 x 125 cm, escoplo 60 mm, Truper.jpg"
import dado145 from "../img/productos/DadosyPuntas/145.-Barreta de punta de 1 x 150 cm, escoplo 67 mm, Truper.jpg"
import dado146 from "../img/productos/DadosyPuntas/146.-Barreta de punta de 1 x 175 cm, escoplo 67 mm, Truper.jpg"
import dado147 from "../img/productos/DadosyPuntas/147.-Juego 7 llaves allen mm punta hexagonal con organizador.jpg"

import './dados.css'
import {Link} from "react-router-dom";

function Dados() {
  return (
    <div className='dados'>
        <div><Link to="/dado1"><img src={dado1}></img><br/>Dado de impacto de 26 mm,<br/>6 puntas, cuadro 1-2, Truper</Link></div>
        <div><Link to="/dado2"><img src={dado2}></img><br/>Dado de impacto de 27 mm, <br/>6 puntas, cuadro 1-2, Truper</Link></div>
        <div><Link to="/dado3"><img src={dado3}></img><br/>Dado largo impacto de 22 mm,<br/> 6 puntas, cuadro 12, Truper</Link></div>
        <div><Link to="/dado4"><img src={dado4}></img><br/>Dado largo impacto de 23 mm,<br/> 6 puntas, cuadro 12, Truper</Link></div>
        <div><Link to="/dado5"><img src={dado5}></img><br/>Dado largo impacto de 24 mm, <br/>6 puntas, cuadro 12, Truper</Link></div>
        <div><Link to="/dado6"><img src={dado6}></img><br/>Dado largo impacto de 25 mm,<br/> 6 puntas, cuadro 12, Truper</Link></div>
        <div><Link to="/dado7"><img src={dado7}></img><br/>Dado largo impacto de 26 mm,<br/> 6 puntas, cuadro 12, Truper</Link></div>
        <div><Link to="/dado8"><img src={dado8}></img><br/>Dado largo impacto de 27 mm,<br/>6 puntas, cuadro 12, Truper</Link></div>
        <div><Link to="/dado9"><img src={dado9}></img><br/>Dado largo de impacto de 1-3-16,<br/> 6 puntas, cuadro 3-4</Link></div>
        <div><Link to="/dado10"><img src={dado10}></img><br/>Dado largo de 5.5 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado11"><img src={dado11}></img><br/>Dado largo de 14, 6 puntas,<br/> cuadro 38, Truper</Link></div>
        <div><Link to="/dado12"><img src={dado12}></img><br/>Dado largo de 516, 6 puntas,<br/> cuadro 38, Truper</Link></div>
        <div><Link to="/dado13"><img src={dado13}></img><br/>Dado largo de 6 mm, 6 puntas,<br/> cuadro 38, Truper</Link></div>
        <div><Link to="/dado14"><img src={dado14}></img><br/>Dado largo de 7 mm, 6 puntas, <br/>cuadro 38, Truper</Link></div>
        <div><Link to="/dado15"><img src={dado15}></img><br/>Dado largo de 8 mm, 6 puntas,<br/> cuadro 38, Truper</Link></div>
        <div><Link to="/dado16"><img src={dado16}></img><br/>Dado largo de 9 mm, 6 puntas,<br/> cuadro 38, Truper</Link></div>
        <div><Link to="/dado17"><img src={dado17}></img><br/>Dado largo de 4 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado18"><img src={dado18}></img><br/>Dado largo de 20 mm, 6 puntas,<br/> cuadro 38, Truper</Link></div>
        <div><Link to="/dado19"><img src={dado19}></img><br/>Dado largo de 1-116, 6 puntas,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado20"><img src={dado20}></img><br/>Dado de 14 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado21"><img src={dado21}></img><br/>Dado de 916, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado22"><img src={dado22}></img><br/>Dado de 6 mm, 12 puntas,<br/> cuadro 38, Truper</Link></div>
        <div><Link to="/dado23"><img src={dado23}></img><br/>Dado de 5.5 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado24"><img src={dado24}></img><br/>Dado largo de 1-18, 6 puntas,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado25"><img src={dado25}></img><br/>Dado largo de 1-316, 6 puntas,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado26"><img src={dado26}></img><br/>Dado largo de 22 mm, 6 puntas,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado27"><img src={dado27}></img><br/>Dado largo de 23 mm, 6 puntas,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado28"><img src={dado28}></img><br/>Dado largo de 20 mm, 6 puntas,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado29"><img src={dado29}></img><br/>Dado largo de 1-14, 6 puntas,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado30"><img src={dado30}></img><br/>Dado largo de 1-316 12 puntas,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado31"><img src={dado31}></img><br/>Dado de 26 mm, 6 puntas,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado32"><img src={dado32}></img><br/>Dado de 27 mm, 6 puntas,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado33"><img src={dado33}></img><br/>Dado de 4 mm, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado34"><img src={dado34}></img><br/>Dado de 12 mm, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado35"><img src={dado35}></img><br/>Dado de 13 mm, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado36"><img src={dado36}></img><br/>Dado de 14 mm, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado37"><img src={dado37}></img><br/>Dado de 5 mm, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado38"><img src={dado38}></img><br/>Dado de 5.5 mm, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado39"><img src={dado39}></img><br/>Dado de 6 mm, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado40"><img src={dado40}></img><br/>Dado de 7 mm, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado41"><img src={dado41}></img><br/>Dado de 8 mm, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado42"><img src={dado42}></img><br/>Dado de 9 mm, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado43"><img src={dado43}></img><br/>Dado de 10 mm, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado44"><img src={dado44}></img><br/>Dado de 11 mm, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado45"><img src={dado45}></img><br/>Dado de 5-32, 12 puntas,<br/> cuadro 1-4, Truper</Link></div>
        <div><Link to="/dado46"><img src={dado46}></img><br/>Dado de 12, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado47"><img src={dado47}></img><br/>Dado de 916, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado49"><img src={dado49}></img><br/>Dado de 316, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado50"><img src={dado50}></img><br/>Dado de 732, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado51"><img src={dado51}></img><br/>Dado de 14, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado52"><img src={dado52}></img><br/>Dado de 932, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado53"><img src={dado53}></img><br/>Dado de 516, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado54"><img src={dado54}></img><br/>Dado de 1132, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado55"><img src={dado55}></img><br/>Dado de 38, 12 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado56"><img src={dado56}></img><br/>Dado de 7-16, 12 puntas,<br/> cuadro 1-4, Truper</Link></div>
        <div><Link to="/dado57"><img src={dado57}></img><br/>Dado de 4 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado58"><img src={dado58}></img><br/>Dado de 13 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado59"><img src={dado59}></img><br/>Dado de 5 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado60"><img src={dado60}></img><br/>Dado de 6 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado61"><img src={dado61}></img><br/>Dado de 7 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado62"><img src={dado62}></img><br/>Dado de 8 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado63"><img src={dado63}></img><br/>Dado de 9 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado64"><img src={dado64}></img><br/>Dado de 10 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado65"><img src={dado65}></img><br/>Dado de 11 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado66"><img src={dado66}></img><br/>Dado de 12 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado67"><img src={dado67}></img><br/>Dado largo de 6 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado68"><img src={dado68}></img><br/>Dado largo de 7 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado69"><img src={dado69}></img><br/>Dado largo de 8 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado70"><img src={dado70}></img><br/>Dado largo de 9 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado71"><img src={dado71}></img><br/>Dado largo de 10 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado72"><img src={dado72}></img><br/>Dado largo de 11 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado73"><img src={dado73}></img><br/>Dado largo de 12 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado74"><img src={dado74}></img><br/>Dado largo de 13 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado75"><img src={dado75}></img><br/>Dado largo de 14 mm, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado76"><img src={dado76}></img><br/>Dado largo de 14, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado77"><img src={dado77}></img><br/>Dado largo de 932, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado78"><img src={dado78}></img><br/>Dado largo de 516, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado79"><img src={dado79}></img><br/>Dado largo de 1132, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado80"><img src={dado80}></img><br/>Dado largo de 38, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado81"><img src={dado81}></img><br/>Dado largo de 716, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado82"><img src={dado82}></img><br/>Dado largo de 12, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado83"><img src={dado83}></img><br/>Dado largo de 916, 6 puntas,<br/> cuadro 14, Truper</Link></div>
        <div><Link to="/dado84"><img src={dado84}></img><br/>Dado largo impacto prot. plást.<br/> 17 mm, 6 puntas, cuadro 12</Link></div>
        <div><Link to="/dado85"><img src={dado85}></img><br/>Dado largo impacto prot. plást.<br/> 19 mm, 6 puntas, cuadro 12</Link></div>
        <div><Link to="/dado86"><img src={dado86}></img><br/>Dado largo impacto prot. plást.<br/> 21 mm, 6 puntas, cuadro 12</Link></div>
        <div><Link to="/dado87"><img src={dado87}></img><br/>Dado largo impacto prot. plást.<br/> 34, 6 puntas, cuadro 12</Link></div>
        <div><Link to="/dado88"><img src={dado88}></img><br/>Dado largo impacto prot. plást.<br/> 1316, 6 puntas,cuadro 12</Link></div>
        <div><Link to="/dado89"><img src={dado89}></img><br/>Dado largo impacto prot. plást.<br/> 78, 6 puntas, cuadro 12</Link></div>
        <div><Link to="/dado90"><img src={dado90}></img><br/>Dado punta larga bristol M5,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado91"><img src={dado91}></img><br/>Dado punta larga bristol M6,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado92"><img src={dado92}></img><br/>Dado punta larga bristol M8,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado93"><img src={dado93}></img><br/>Dado punta larga bristol M10,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado94"><img src={dado94}></img><br/>Dado punta larga bristol M12,<br/> cuadro 1-2, Truper</Link></div>
        <div><Link to="/dado95"><img src={dado95}></img><br/>Dado punta larga bristol M14,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado96"><img src={dado96}></img><br/>Dado punta larga bristol M16,<br/> cuadro 12, Truper</Link></div>
        <div><Link to="/dado97"><img src={dado97}></img><br/>Dado para termofusora <br/>de 1-14 40 mm, Foset</Link></div>
        <div><Link to="/dado98"><img src={dado98}></img><br/>Dado para termofusora <br/>de 1-12 50 mm, Foset</Link></div>
        <div><Link to="/dado99"><img src={dado99}></img><br/>Dado para termofusora <br/>de 2 63 mm, Foset</Link></div>
        <div><Link to="/dado100"><img src={dado100}></img><br/>Dado para termofusora <br/>de 12 20 mm, Foset</Link></div>
        <div><Link to="/dado101"><img src={dado101}></img><br/>Dado para termofusora <br/>de 3-4 25 mm, Foset</Link></div>
        <div><Link to="/dado102"><img src={dado102}></img><br/>Dado para termofusora<br/> de 1 32 mm, Foset</Link></div>
        <div><Link to="/dado103"><img src={dado103}></img><br/>Dados de punta corta,<br/> cuadro 12</Link></div>
        <div><Link to="/dado104"><img src={dado104}></img><br/>Dado punta corta bristol M6,<br/> cuadro 1-2, Truper</Link></div>
        <div><Link to="/dado105"><img src={dado105}></img><br/>Dado punta corta bristol M8,<br/> cuadro 1-2, Truper</Link></div>
        <div><Link to="/dado106"><img src={dado106}></img><br/>Dado punta corta bristol M10,<br/> cuadro 1-2, Truper</Link></div>
        <div><Link to="/dado107"><img src={dado107}></img><br/>Dado punta corta bristol M12,<br/> cuadro 1-2, Truper</Link></div>
        <div><Link to="/dado108"><img src={dado108}></img><br/>Dado punta corta bristol M14,<br/> cuadro 1-2, Truper</Link></div>
        <div><Link to="/dado109"><img src={dado109}></img><br/>Dado punta corta bristol M16,<br/> cuadro 1-2, Truper</Link></div>
        <div><Link to="/dado110"><img src={dado110}></img><br/>Punta de repuesto para cautín<br/> tipo lápiz CAU-300X, Truper</Link></div>
        <div><Link to="/dado111"><img src={dado111}></img><br/>Punta de repuesto para cautín<br/> tipo lápiz CAU-150X, Truper</Link></div>
        <div><Link to="/dado112"><img src={dado112}></img><br/>Punta pol de latón 1-4 x 5 cm,<br/> de rosca con O-ring, Foset</Link></div>
        <div><Link to="/dado113"><img src={dado113}></img><br/>Punta pol de latón 3-8 x 6 cm,<br/> de espiga, Foset</Link></div>
        <div><Link to="/dado114"><img src={dado114}></img><br/>Punta pol de latón 3-8 x 12 cm,<br/> de espiga, Foset</Link></div>
        <div><Link to="/dado115"><img src={dado115}></img><br/>Punta pol de latón 1-4 x 6 cm,<br/> de rosca, Foset</Link></div>
        <div><Link to="/dado116"><img src={dado116}></img><br/>Cincel de punta SDS max de 16,<br/> Truper</Link></div>
        <div><Link to="/dado117"><img src={dado117}></img><br/>Cincel de punta zanco <br/>hexagonal de 20, Truper</Link></div>
        <div><Link to="/dado118"><img src={dado118}></img><br/>Cincel de punta SDS <br/>Plus de 10, Truper</Link></div>
        <div><Link to="/dado119"><img src={dado119}></img><br/>Dado punta hexagonal <br/>de 3-16, cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado120"><img src={dado120}></img><br/>Dado punta hexagonal <br/>de 7-32, cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado121"><img src={dado121}></img><br/>Dado punta hexagonal <br/>de 1-4, cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado122"><img src={dado122}></img><br/>Dado punta hexagonal <br/>de 5-16, cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado123"><img src={dado123}></img><br/>Dado punta hexagonal <br/>de 3-8, cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado124"><img src={dado124}></img><br/>Dado punta hexagonal <br/>de 4 mm, cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado125"><img src={dado125}></img><br/>Dado punta hexagonal <br/>de 5 mm, cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado126"><img src={dado126}></img><br/>Dado punta hexagonal <br/>de 6 mm, cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado127"><img src={dado127}></img><br/>Dado punta hexagonal <br/>de 7 mm, cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado128"><img src={dado128}></img><br/>Dado punta hexagonal <br/>de 8 mm, cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado129"><img src={dado129}></img><br/>Dado punta hexagonal <br/>de 10 mm, cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado130"><img src={dado130}></img><br/>Dado punta torx T-15, <br/>cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado131"><img src={dado131}></img><br/>Dado punta torx T-60, <br/>cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado132"><img src={dado132}></img><br/>Dado punta torx T-20, <br/>cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado133"><img src={dado133}></img><br/>Dado punta torx T-25, <br/>cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado134"><img src={dado134}></img><br/>Dado punta torx T-27, <br/>cuadro 3-8,Truper</Link></div>
        <div><Link to="/dado135"><img src={dado135}></img><br/>Dado punta torx T-30, <br/>cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado136"><img src={dado136}></img><br/>Dado punta torx T-40, <br/>cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado137"><img src={dado137}></img><br/>Dado punta torx T-45, <br/>cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado138"><img src={dado138}></img><br/>Dado punta torx T-50, <br/>cuadro 3-8 Truper</Link></div>
        <div><Link to="/dado139"><img src={dado139}></img><br/>Dado punta torx T-55, <br/>cuadro 3-8, Truper</Link></div>
        <div><Link to="/dado140"><img src={dado140}></img><br/>Barreta de punta de 1-1-4 x 175 cm,<br/> escoplo 80 mm, Truper</Link></div>
        <div><Link to="/dado141"><img src={dado141}></img><br/>Barreta de punta de 1 x 150 cm,<br/> escoplo 72 mm, Truper</Link></div>
        <div><Link to="/dado142"><img src={dado142}></img><br/>Barreta de punta de 1x 175 cm,<br/> escoplo 72 mm, Truper</Link></div>
        <div><Link to="/dado143"><img src={dado143}></img><br/>Barreta de punta de 7-8 x 100 cm,<br/> escoplo 60 mm, Truper</Link></div>
        <div><Link to="/dado144"><img src={dado144}></img><br/>Barreta de punta de 7-8 x 125 cm,<br/> escoplo 60 mm, Truper</Link></div>
        <div><Link to="/dado145"><img src={dado145}></img><br/>Barreta de punta de 1 x 150 cm,<br/> escoplo 67 mm, Truper</Link></div>
        <div><Link to="/dado146"><img src={dado146}></img><br/>Barreta de punta de 1 x 175 cm,<br/> escoplo 67 mm, Truper</Link></div>
        <div><Link to="/dado147"><img src={dado147}></img><br/>Juego 7 llaves allen mm punta <br/>hexagonal con organizador</Link></div>
       


    </div>
  )
}

export default Dados