import React from 'react'
import HerramientaElectricaBateria1 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/1._Llave_angular_inalambrica_QXN.jpg"
import HerramientaElectricaBateria2 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/2.-Atornillador angular de alto torque ETS Ingersoll Rand QXX5AT80ES08.jpg"
import HerramientaElectricaBateria3 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/3.-Atornillador de impacto inalambrico DTD154RFE.jpg"
import HerramientaElectricaBateria4 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/4.-Atornillador de precisión inalámbrico ETS QXX2PT18ES6.jpg"
import HerramientaElectricaBateria5 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/5.-Atornillador inalambrico DDF483RAE.jpg"
import HerramientaElectricaBateria6 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/6.-Atornillador inalambrico DF330D.jpg"
import HerramientaElectricaBateria7 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/7.-Atornillador inalambrico QXX2PT12PS6.jpg"
import HerramientaElectricaBateria8 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/8.-Calafateadora inalambrico DCG180RME.jpg"
/* import HerramientaElectricaBateria9 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/" */
import HerramientaElectricaBateria10 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/10.-Cortadora de varilla de acero inalambrico DSC191Z.jpg"
import HerramientaElectricaBateria11 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/11.-Esmeriladora inalambrica DGA458Z.jpg"
import HerramientaElectricaBateria12 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/12.-Lijadora orbital inalambrico XOB01.jpg"
import HerramientaElectricaBateria13 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/13.-Llave de impacto angular DTL063Z.jpg"
import HerramientaElectricaBateria14 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/14.-Llave de impacto inalambrico WT01.jpg"
import HerramientaElectricaBateria15 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/15.-Llave de trinquete de 12” R3150.jpg"
import HerramientaElectricaBateria16 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/16.-Llave de trinquete de 14” R1120.jpg"
import HerramientaElectricaBateria17 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/17.-Llave de trinquete de 3-8” R3130.jpg"
import HerramientaElectricaBateria18 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/18.-Multicortador inalambrico DCO180Z.jpg"
import HerramientaElectricaBateria19 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/19.-Multiplicador de torsion QXX5A45T0180PS12.jpg"
import HerramientaElectricaBateria20 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/20.-Pistola de presicion inalambrica ETS QXX2PT18EQ4.jpg"
import HerramientaElectricaBateria21 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/21.-Roto martillo inalambrico GD0811C.jpg"
import HerramientaElectricaBateria22 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/22.-Roto martillo inalambrico PH03.jpg"
import HerramientaElectricaBateria23 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/23.-Sierra circular inalambrica XSS02Z.jpg"
import HerramientaElectricaBateria24 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/24.-Taladro atornillador inalambrico DDF459Z.jpg"
import HerramientaElectricaBateria25 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/25.-Taladro inalambrico de alta torsion de 1 2V de 38” D1130.jpg"
import HerramientaElectricaBateria26 from "../img/productos/HerramientaElectrica/herramientaElectricaBateria/26.-Taladro inalambrico de alta torsion de 20V de 12” D5140.jpg"


import  {Link} from "react-router-dom"
import './herramientaElectricaBateria.css'


function HerramientaElectricaBateria() {

  return (
    <div className='herramientaElectricaBateria'>
            
    <div><Link to="/HEB1"><img src={HerramientaElectricaBateria1}></img><br/>Llave angular<br/> inalambrica QXN</Link></div>
    <div><Link to="/HEB2"><img src={HerramientaElectricaBateria2}></img><br/>Atornillador angular<br/> de alto torqueETS<br/> QXX5AT80ES08</Link></div>
    <div><Link to="/HEB3"><img src={HerramientaElectricaBateria3}></img><br/>Atornillador de impacto<br/> inalambrico<br/> DTD154RFE</Link></div>
    <div><Link to="/HEB4"><img src={HerramientaElectricaBateria4}></img><br/>Atornillador de precisión<br/>inalámbrico ETS<br/> QXX2PT18ES6</Link></div>
    <div><Link to="/HEB5"><img src={HerramientaElectricaBateria5}></img><br/>Atornillador inalambrico<br/> DDF483RAE</Link></div>
    <div><Link to="/HEB6"><img src={HerramientaElectricaBateria6}></img><br/>Atornillador inalambrico<br/> DF330D</Link></div>
    <div><Link to="/HEB7"><img src={HerramientaElectricaBateria7}></img><br/>Atornillador inalambrico<br/> QXX2PT12PS6</Link></div>
    <div><Link to="/HEB8"><img src={HerramientaElectricaBateria8}></img><br/>Calafateadora inalambrico<br/> DCG180RME</Link></div>
    {/* <div><Link to="/HEB9"><img src={HerramientaElectricaBateria9}></img></Link></div> */}
    <div><Link to="/HEB10"><img src={HerramientaElectricaBateria10}></img><br/>Cortadora de varilla de acero<br/> inalambrico DSC191Z</Link></div>
    <div><Link to="/HEB11"><img src={HerramientaElectricaBateria11}></img><br/>Esmeriladora inalambrica<br/> DGA458Z</Link></div>
    <div><Link to="/HEB12"><img src={HerramientaElectricaBateria12}></img><br/>Lijadora orbital inalambrico<br/> XOB01</Link></div>
    <div><Link to="/HEB13"><img src={HerramientaElectricaBateria13}></img><br/>Llave de impacto angular<br/>DTL063Z</Link></div>
    <div><Link to="/HEB14"><img src={HerramientaElectricaBateria14}></img><br/>Llave de impacto <br/>inalambrico WT01</Link></div>
    <div><Link to="/HEB15"><img src={HerramientaElectricaBateria15}></img><br/>Llave de trinquete<br/> de 12” R3150</Link></div>
    <div><Link to="/HEB16"><img src={HerramientaElectricaBateria16}></img><br/>Llave de trinquete<br/> de 14” R1120</Link></div>
    <div><Link to="/HEB17"><img src={HerramientaElectricaBateria17}></img><br/>Llave de trinquete <br/>de 3-8” R3130</Link></div>
    <div><Link to="/HEB18"><img src={HerramientaElectricaBateria18}></img><br/>Multicortador inalambrico<br/> DCO180Z</Link></div>
    <div><Link to="/HEB19"><img src={HerramientaElectricaBateria19}></img><br/>Multiplicador de torsion <br/>QXX5A45T0180PS12</Link></div>
    <div><Link to="/HEB20"><img src={HerramientaElectricaBateria20}></img><br/>Pistola de presicion <br/> inalambrica<br/> ETS QXX2PT18EQ4</Link></div>
    <div><Link to="/HEB21"><img src={HerramientaElectricaBateria21}></img><br/>Roto martillo inalambrico<br/> GD0811C</Link></div>
    <div><Link to="/HEB22"><img src={HerramientaElectricaBateria22}></img><br/>Roto martillo inalambrico<br/> PH03</Link></div>
    <div><Link to="/HEB23"><img src={HerramientaElectricaBateria23}></img><br/>Sierra circular inalambrica<br/> XSS02Z</Link></div>
    <div><Link to="/HEB24"><img src={HerramientaElectricaBateria24}></img><br/>Taladro atornillador inalambrico<br/> DDF459Z</Link></div>
    <div><Link to="/HEB25"><img src={HerramientaElectricaBateria25}></img><br/>Taladro inalambrico de alta<br/> torsion de 1 2V de 38” D1130</Link></div>
    <div><Link to="/HEB26"><img src={HerramientaElectricaBateria26}></img><br/>Taladro inalambrico de alta<br/> torsion de 20V de 12” D5140</Link></div>
    

   





    </div>
  )
  
}


export default HerramientaElectricaBateria