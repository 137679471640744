import React from 'react'
import {Link} from "react-router-dom"
import './avisoPrivacidad.css'

function AvisoPrivacidad() {
  return (
    <div className='aviso_privacidad'>

      <h2>AVISO DE PRIVACIDAD</h2>

<h2>ARG SOLUCIONES INDUSTRIALES DE SONORA S.A. DE C.V.</h2>
<h2>IDENTIDAD Y DOMICILIO DEL RESPONSABLE</h2><br/>
<p>
Con fundamento en los artículos 15, 16 y sucesivos de la Ley Federal de Protección de
Datos Personales en Posesión de los Particulares (LFPDPPP), y su reglamento, hacemos
de su conocimiento que <strong>ARG SOLUCIONES INDUSTRIALES DE SONORA S.A. DE C.V.</strong>
(en lo sucesivo <strong>la Empresa</strong>), con domicilio en Hermosillo, Sonora, es responsable de
recabar, usar y proteger los datos personales de nuestros clientes, en cumplimiento con los
principios de Licitud, Calidad, Consentimiento, Información, Finalidad, Lealtad,
Proporcionalidad y Responsabilidad, generando de esta manera la confianza para que el
titular proporcione sus datos personales.
</p><br/>
<h2>FINALIDADES PRIMARIAS DEL TRATAMIENTO DE LOS DATOS</h2><br/>

<p>Los datos personales que <strong>LA EMPRESA</strong> recabe serán utilizados para cumplir con las
obligaciones derivadas de la relación de prestación de servicios profesionales que existe
entre la Empresa y el cliente,<strong> finalidades que son exclusivas</strong> y detalladas a continuación:
Proveer los productos y servicios disponibles a través de las plataformas digitales; Compras
en línea; Entrega del producto o servicio en el domicilio proporcionado por el cliente; Para la
atención, seguimiento de dudas, quejas y sugerencias del cliente; Realizar la facturación por
la compra de los productos y servicios, en su caso; Realización de estudios, registros,
estadísticas y análisis de información de la evolución de los clientes; Cumplimiento con
obligaciones fiscales y legales;
No se podrá hacer uso de sus datos personales para otras finalidades diferentes a las
descritas a menos que dichas finalidades sean compatibles y puedan considerarse análogas
a las anteriores salvo que medie un cambio en este aviso de privacidad.
</p>
<h2><br/>
CONSENTIMIENTO
</h2><br/>
<p>De acuerdo a lo indicado en el artículo 8 de la Ley Federal de Datos Personales en Posesión
de los Particulares, se entenderá que el titular consiente tácitamente el tratamiento de sus
datos, cuando habiéndose puesto a su disposición el presente Aviso de Privacidad, no
manifieste su oposición al respecto.
</p>
<p>
Tratándose de los datos personales financieros y/o patrimoniales, obtenemos su
consentimiento expreso mediante su firma en el presente Aviso de Privacidad.
</p>
<p>
Así mismo usted declara y acepta que cuenta con el consentimiento de aquellos terceros de
los cuales usted proporciona sus datos, en el entendido de que dichos datos se regirán por
el presente Aviso de Privacidad.
</p><br/>
<h2>
IDENTIDAD DEL ENCARGADO
</h2><br/>
<p>
El Departamento a continuación señalado, es el área designada por la Empresa, encargada
de la seguridad de sus datos personales para que éstos sean tratados de acuerdo a la
legislación aplicable y vigente en el país.
</p>
<p>
Encargado: Oficial de privacidad<br/>
Correo
Electrónico: Contacto@argsoluciones.con.mx
</p>
<p>
Para conocer mayor información sobre los términos y condiciones en que serán tratados sus
datos personales, como a los terceros con quienes compartimos su información personal y la
forma en que podrá limitar su uso y divulgación, así como ejercer sus derechos ARCO,
puede consultar el Aviso de Privacidad Integral a través de nuestra página web: &nbsp;
<Link to="/" className='blue'>www.argsoluciones.com.mx.</Link>
</p><br/><br/><br/>
    </div>
  )
}

export default AvisoPrivacidad