import React from 'react'
import './herramientaElectricaBateria1Desc.css'
import imgPruebaa from '../../img/productos/HerramientaElectrica/herramientaElectricaBateria/5.-Atornillador inalambrico DDF483RAE.jpg';
/* import imgPruebaa from "../img/productos/HerramientaElectrica/HerramientaElectricaBateria/1._Llave_angular_inalambrica_QXN.jpg" */
import {Link} from "react-router-dom"

function HerramientaElectricaBateria1Desc() {
  return (
    <div className='herramientaElectricaBateria1Desc'>

      <div className='herramientaElectricaBateria1Desc_left'>
        <img src={imgPruebaa}></img>
      </div>

      <div className='herramientaElectricaBateria1Desc_right'>
        <h2>Atornillador inalambrico<br/> DDF483RAE</h2><br/>
        <p><strong>SKU:</strong>  DDF483RAE</p>
        <p><strong>Categorías:</strong> Herramienta electrica de bateria, Makita</p>
        <br />
        <div className='HerramientaElectricaBateria1Descripcion'>
          <h2>Descripción</h2>
          <p>
          Motor bl elimina escobillas de carbón, permitiendo que el motor funcione refrigerado y mas eficiente para mayor vida útil.
          </p><br/><br/>
          <h5>Información Técnica</h5>
          <div className='line'></div>
          <p>
          

          Capacidad de mandril: 1,5 - 13 mm (1/16 - 1/2")<br/><br/>

Velocidad sin carga: Alto / Bajo :0 - 1,700 / 0 - 500<br/><br/>

Torque máximo: Fuerte / Suave: 40 / 23 Nm (350 / 210 in.lbs.)
          </p>
        </div>
<br/>
<Link to="/contacto"><div className='btn_cotizanos'>Cotizanos</div></Link>

      </div>


    </div>
  )
}

export default HerramientaElectricaBateria1Desc