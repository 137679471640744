import {Link} from "react-router-dom"



import React from 'react'
import HerramientaIndustrial1 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/1.-Amoladora angular G3A100RP106.jpg"
import HerramientaIndustrial2 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/2.-Amoladora de cono y conector 61H120L6.jpg"
import HerramientaIndustrial3 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/3.-Amoladora de cono y conector 77H90L10.jpg"
import HerramientaIndustrial4 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/4.-Amoladora Serie 61 Pro 61H120H63.jpg"
import HerramientaIndustrial5 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/5.-Amoladora tipo lápiz 7114-E.jpg"
import HerramientaIndustrial6 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/6.-Amoladora vertical 88V60P109.jpg"
import HerramientaIndustrial7 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/7.-Amoladora verticale 99V60P107.jpg"
import HerramientaIndustrial8 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/8.-Aprietatuercas angular 19RAA04AM2.jpg"
import HerramientaIndustrial9 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/9.-Aprietatuercas QA6.jpg"
import HerramientaIndustrial10 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/10.-Aprietatuercas reversible 75RNL-2X-6.jpg"
import HerramientaIndustrial11 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/11.-Desarmador neumatico 41PA.jpg"
import HerramientaIndustrial12 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/12.-Desarmador neumatico 1RP.jpg"
import HerramientaIndustrial13 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/13.-Desarmador neumatico 41SA.jpg"
import HerramientaIndustrial14 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/14.-Desarmador neumatico QA4AALS.jpg"
import HerramientaIndustrial15 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/15.-Desarmador neumatico QP1T.jpg"
import HerramientaIndustrial16 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/16.-Destornillador en línea QS1P10S1D.jpg"
import HerramientaIndustrial17 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/17.-Destornillador neumatico 19TTA15Q.jpg"
import HerramientaIndustrial18 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/18.-Destornillador neumatico 35RSA-5Q.jpg"
import HerramientaIndustrial19 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/19.-Escareador de aguja 172LNA1.jpg"
import HerramientaIndustrial20 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/20.-Escareador de cincel 172L.jpg"
import HerramientaIndustrial21 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/21.-Herramienta cónica 7RAQT4.jpg"
import HerramientaIndustrial22 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/22.-Herramienta de pulso 35PTHH403.jpg"
import HerramientaIndustrial23 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/23.-Lijadora 7P24L Serie Pro 7P.jpg"
import HerramientaIndustrial24 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/24.-Lijadora 88S60W107 Serie Pro 88S.jpg"
import HerramientaIndustrial25 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/25.-Lijadora angular extendida 77A45W109.jpg"
import HerramientaIndustrial26 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/26.-Lijadora angular extendida M2L025RS10.jpg"
import HerramientaIndustrial27 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/27.-Lijadora de banda angular G1A200RS818.jpg"
import HerramientaIndustrial28 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/28.-Martillo cincelador 2A2SA Serie A.jpg"
import HerramientaIndustrial29 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/29.-Martillo cincelador 2DA1SA Serie D.jpg"
import HerramientaIndustrial30 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/30.-Mini Matraca 105-D3 Serie 105.jpg"
import HerramientaIndustrial31 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/31.-Pistola de impacto 3-4” 1720P1.jpg"
import HerramientaIndustrial32 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/32.-Pistola no reversible 5ANST6 Serie 5.jpg"
import HerramientaIndustrial33 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/33.-Pistola no reversible 5RANST8 Serie 5.jpg"
import HerramientaIndustrial34 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/34.-Taladro angular 5LK1B1 Serie 5.jpg"
import HerramientaIndustrial35 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/35.-Taladro angular 7LN3A44 Serie 7.jpg"
import HerramientaIndustrial36 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/36.-Taladro angular QA2739D Serie Q2.jpg"
import HerramientaIndustrial37 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/37.-Taladro en línea 1S30MF4 Serie 1.jpg"
import HerramientaIndustrial38 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/38.-Taladro en línea 6LR3 Serie 6.jpg"
import HerramientaIndustrial39 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/39.-Taladro neumatico QP201D.jpg"
import HerramientaIndustrial40 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/40.-Taladro tipo pistola 1P38ST4 Serie 1.jpg"
import HerramientaIndustrial41 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/41.-Taladro tipo pistola 6AJJST4 Serie 6.jpg"
import HerramientaIndustrial42 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/42.-Taladro tipo pistola 7AKST6 Serie 7.jpg"
import HerramientaIndustrial43 from "../img/productos/HerramientaNeumatica/HerramientaIndustrial/43.-Turbina recta 1-4” G1H200RG4.jpg"

import './herramientaIndustrial.css'

function HerramientaIndustrial() {
  return (
    <div className='herramientaIndustrial'>
      

      
    <div><Link to="/HI1"><img src={HerramientaIndustrial1}></img><br/>Amoladora angular <br/>G3A100RP106</Link></div>
    <div><Link to="/HI2"><img src={HerramientaIndustrial2}></img><br/>Amoladora de cono <br/>y conector 61H120L6</Link></div>
    <div><Link to="/H13"><img src={HerramientaIndustrial3}></img><br/>Amoladora de cono y<br/> conector 77H90L10</Link></div>
    <div><Link to="/HI4"><img src={HerramientaIndustrial4}></img><br/>Amoladora Serie 61<br/> Pro 61H120H63</Link></div>
    <div><Link to="/HI5"><img src={HerramientaIndustrial5}></img><br/>Amoladora tipo lápiz<br/> 7114-E</Link></div>
    <div><Link to="/HI6"><img src={HerramientaIndustrial6}></img><br/>Amoladora vertical<br/> 88V60P109</Link></div>
    <div><Link to="/HI7"><img src={HerramientaIndustrial7}></img><br/>Amoladora verticale<br/> 99V60P107</Link></div>
    <div><Link to="/HI8"><img src={HerramientaIndustrial8}></img><br/>Aprietatuercas angular<br/>19RAA04AM2</Link></div>
    <div><Link to="/HI9"><img src={HerramientaIndustrial9}></img><br/>Aprietatuercas QA6</Link></div>
    <div><Link to="/HI10"><img src={HerramientaIndustrial10}></img><br/>Aprietatuercas reversible<br/> 75RNL-2X-6</Link></div>
    <div><Link to="/HI11"><img src={HerramientaIndustrial11}></img><br/>Desarmador neumatico<br/> 41PA</Link></div>
    <div><Link to="/HI12"><img src={HerramientaIndustrial12}></img><br/>Desarmador neumatico<br/> 1RP</Link></div>
    <div><Link to="/HI13"><img src={HerramientaIndustrial13}></img><br/>Desarmador neumatico<br/> 41SA</Link></div>
    <div><Link to="/HI14"><img src={HerramientaIndustrial14}></img><br/>Desarmador neumatico<br/> QA4AALS</Link></div>
    <div><Link to="/HI15"><img src={HerramientaIndustrial15}></img><br/>Desarmador neumatico<br/> QP1T</Link></div>
    <div><Link to="/HI16"><img src={HerramientaIndustrial16}></img><br/>Destornillador en línea<br/>QS1P10S1D</Link></div>
    <div><Link to="/HI17"><img src={HerramientaIndustrial17}></img><br/>Destornillador neumatico<br/> 19TTA15Q</Link></div>
    <div><Link to="/HI18"><img src={HerramientaIndustrial18}></img><br/>Destornillador neumatico<br/> 35RSA-5Q</Link></div>
    <div><Link to="/HI19"><img src={HerramientaIndustrial19}></img><br/>Escareador de aguja<br/> 172LNA1</Link></div>
    <div><Link to="/HI20"><img src={HerramientaIndustrial20}></img><br/>Escareador de cincel<br/> 172L</Link></div>
    <div><Link to="/HI21"><img src={HerramientaIndustrial21}></img><br/>Herramienta cónica<br/> 7RAQT4</Link></div>
    <div><Link to="/HI22"><img src={HerramientaIndustrial22}></img><br/>Herramienta de pulso<br/> 35PTHH403</Link></div>
    <div><Link to="/HI23"><img src={HerramientaIndustrial23}></img><br/>Lijadora 7P24L <br/> Serie Pro 7P</Link></div>
    <div><Link to="/HI24"><img src={HerramientaIndustrial24}></img><br/>Lijadora 88S60W107 <br/>Serie Pro 88S</Link></div>
    <div><Link to="/HI25"><img src={HerramientaIndustrial25}></img><br/>Lijadora angular extendida<br/> 77A45W109</Link></div>
    <div><Link to="/HI26"><img src={HerramientaIndustrial26}></img><br/>Lijadora angular extendida <br/>M2L025RS10</Link></div>
    <div><Link to="/HI27"><img src={HerramientaIndustrial27}></img><br/>Lijadora de banda angular<br/> G1A200RS818</Link></div>
    <div><Link to="/HI28"><img src={HerramientaIndustrial28}></img><br/>Martillo cincelador <br/>2A2SA Serie A</Link></div>
    <div><Link to="/HI29"><img src={HerramientaIndustrial29}></img><br/>Martillo cincelador<br/> 2DA1SA Serie D</Link></div>
    <div><Link to="/HI30"><img src={HerramientaIndustrial30}></img><br/>Mini Matraca 105-D3 <br/>Serie 105</Link></div>
    <div><Link to="/HI31"><img src={HerramientaIndustrial31}></img><br/>Pistola de impacto 3-4”<br/> 1720P1</Link></div>
    <div><Link to="/HI32"><img src={HerramientaIndustrial32}></img><br/>Pistola no reversible<br/> 5ANST6 Serie 5</Link></div>
    <div><Link to="/HI33"><img src={HerramientaIndustrial33}></img><br/>Pistola no reversible<br/> 5RANST8 Serie 5</Link></div>
    <div><Link to="/HI34"><img src={HerramientaIndustrial34}></img><br/>Taladro angular <br/>5LK1B1 Serie 5</Link></div>
    <div><Link to="/HI35"><img src={HerramientaIndustrial35}></img><br/>Taladro angular <br/>7LN3A44 Serie 7</Link></div>
    <div><Link to="/HI36"><img src={HerramientaIndustrial36}></img><br/>Taladro angular <br/>QA2739D Serie Q2</Link></div>
    <div><Link to="/HI37"><img src={HerramientaIndustrial37}></img><br/>Taladro en línea <br/>1S30MF4 Serie 1</Link></div>
    <div><Link to="/HI38"><img src={HerramientaIndustrial38}></img><br/>Taladro en línea <br/>6LR3 Serie 6</Link></div>
    <div><Link to="/HI39"><img src={HerramientaIndustrial39}></img><br/>Taladro neumatico<br/> QP201D</Link></div>
    <div><Link to="/HI40"><img src={HerramientaIndustrial40}></img><br/>Taladro tipo pistola <br/>1P38ST4 Serie 1</Link></div>
    <div><Link to="/HI41"><img src={HerramientaIndustrial41}></img><br/>Taladro tipo pistola <br/>6AJJST4 Serie 6</Link></div>
    <div><Link to="/HI42"><img src={HerramientaIndustrial42}></img><br/>Taladro tipo pistola<br/> 7AKST6 Serie 7</Link></div>
    <div><Link to="/HI43"><img src={HerramientaIndustrial43}></img><br/>Turbina recta 1-4”<br/> G1H200RG4</Link></div>

    </div>
  )
}

export default HerramientaIndustrial