import React from 'react'
import EquipoRecubrimiento1 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/1.-Agitador Binks 31-451  31-454.jpg"
import EquipoRecubrimiento2 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/2.-Binks Bombas de Serie Maple 15-25.jpg"
import EquipoRecubrimiento3 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/3.-Binks Bombas de Serie Maple 8-25.jpg"
import EquipoRecubrimiento4 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/4.-Bomba de alta presion MX432 Binks.jpg"
import EquipoRecubrimiento5 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/5.-Bomba de diafragma DX200.jpg"
import EquipoRecubrimiento6 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/6.-Bomba maple 15-3.jpg"
import EquipoRecubrimiento7 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/7.-Bombas Binks MX1212.jpg"
import EquipoRecubrimiento8 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/8.-Bombas Binks MX1231.jpg"
import EquipoRecubrimiento9 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/9.-Bombas Binks MX412.jpg"
import EquipoRecubrimiento10 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/10.-Bombas de diafragma Gemini II.jpg"
import EquipoRecubrimiento11 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/11.-Bombas de Serie Maple.jpg"
import EquipoRecubrimiento12 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/12.-Bombas de Serie Maple 7-15 Binks.jpg"
import EquipoRecubrimiento13 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/13.-Dekups Vasos Desechables.jpg"
import EquipoRecubrimiento14 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/14.-DeVilbiss FLG-5 Pistola.jpg"
import EquipoRecubrimiento15 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/15.-DeVilbiss Pistola de Gravedad TEKNA Copper.jpg"
import EquipoRecubrimiento16 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/16.-HGB Regulador DeVilbiss.jpg"
import EquipoRecubrimiento17 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/17.-Pistola Airless automática AG364.jpg"
import EquipoRecubrimiento18 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/18.-Pistola automática A-G361 DeVilbiss.jpg"
import EquipoRecubrimiento19 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/19.-Pistola automatica A-G362 DeVilbiss.jpg"
import EquipoRecubrimiento20 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/20.-Pistola Automatica AG-363 Binks.jpg"
import EquipoRecubrimiento21 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/21.-Pistola automática AG361E.jpg"
import EquipoRecubrimiento22 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/22.-Pistola automática AGMDPRO.jpg"
import EquipoRecubrimiento23 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/23.-Pistola de pulverización por gravedad PROLite.jpg"
import EquipoRecubrimiento24 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/24.-Pistola manual Compact.jpg"
import EquipoRecubrimiento25 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/25.-Pistola manual Trophy.jpg"
import EquipoRecubrimiento26 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/26.-Pistola rociadora DV1 (CLEARCOAT, BASECOAT) Devilbiss.jpg"
import EquipoRecubrimiento27 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/27.-Pistolas de pulverización manuales sin aire asistidas por aire TROPHY 1600-4400 AIRLESS.jpg"
import EquipoRecubrimiento28 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/28.-Spray Booth.jpg"
import EquipoRecubrimiento29 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/29.-Bomba de diafragma Graco DF3311.jpg"
import EquipoRecubrimiento30 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/30.-Tanque de presión Binks Serie 183G.jpg"
import EquipoRecubrimiento31 from "../img/productos/Equipos de pintura/Equipos de recubrimiento/31.-Tanque de presión Binks Serie 183S.jpg"

import './equiposRecubrimiento.css'
import {Link} from "react-router-dom";

function EquiposRecubrimiento() {
  return (
    <div className='equipos_recubrimiento'>
 <div><Link to="/equipo_recubrimiento1"><img src={EquipoRecubrimiento1}></img><br/>Agitador Binks <br/>31-451  31-454</Link></div>
 <div><Link to="/equipo_recubrimiento2"><img src={EquipoRecubrimiento2}></img><br/>Binks Bombas de Serie<br/> Maple 15-25</Link></div>
 <div><Link to="/equipo_recubrimiento3"><img src={EquipoRecubrimiento3}></img><br/>Binks Bombas de Serie<br/> Maple 8-25</Link></div>
 <div><Link to="/equipo_recubrimiento4"><img src={EquipoRecubrimiento4}></img><br/>Bomba de alta presion <br/>MX432 Binks</Link></div>
 <div><Link to="/equipo_recubrimiento5"><img src={EquipoRecubrimiento5}></img><br/>Bomba de diafragma<br/> DX200</Link></div>
 <div><Link to="/equipo_recubrimiento6"><img src={EquipoRecubrimiento6}></img><br/>Bomba maple <br/>15-3</Link></div>
 <div><Link to="/equipo_recubrimiento7"><img src={EquipoRecubrimiento7}></img><br/>Bombas Binks <br/>MX1212</Link></div>
 <div><Link to="/equipo_recubrimiento8"><img src={EquipoRecubrimiento8}></img><br/>Bombas Binks <br/>MX1231</Link></div>
 <div><Link to="/equipo_recubrimiento9"><img src={EquipoRecubrimiento9}></img><br/>Bombas Binks <br/> MX412</Link></div>
 <div><Link to="/equipo_recubrimiento10"><img src={EquipoRecubrimiento10}></img><br/>Bombas de diafragma <br/>Gemini II</Link></div>
 <div><Link to="/equipo_recubrimiento11"><img src={EquipoRecubrimiento11}></img><br/>Bombas de Serie <br/>Maple</Link></div>
 <div><Link to="/equipo_recubrimiento12"><img src={EquipoRecubrimiento12}></img><br/>Bombas de Serie <br/>Maple 7-15 Binks</Link></div>
 <div><Link to="/equipo_recubrimiento13"><img src={EquipoRecubrimiento13}></img><br/>Dekups Vasos <br/>Desechables</Link></div>
 <div><Link to="/equipo_recubrimiento14"><img src={EquipoRecubrimiento14}></img><br/>DeVilbiss FLG-5 <br/>Pistola</Link></div>
 <div><Link to="/equipo_recubrimiento15"><img src={EquipoRecubrimiento15}></img><br/>DeVilbiss Pistola de Gravedad<br/> TEKNA Copper</Link></div>
 <div><Link to="/equipo_recubrimiento16"><img src={EquipoRecubrimiento16}></img><br/>HGB Regulador<br/> DeVilbiss</Link></div>
 <div><Link to="/equipo_recubrimiento17"><img src={EquipoRecubrimiento17}></img><br/>Pistola Airless <br/>automática AG364</Link></div>
 <div><Link to="/equipo_recubrimiento18"><img src={EquipoRecubrimiento18}></img><br/>Pistola automática <br/>A-G361 DeVilbiss</Link></div>
 <div><Link to="/equipo_recubrimiento19"><img src={EquipoRecubrimiento19}></img><br/>Pistola automatica<br/> A-G362 DeVilbiss</Link></div>
 <div><Link to="/equipo_recubrimiento20"><img src={EquipoRecubrimiento20}></img><br/>Pistola Automatica <br/>AG-363 Binks</Link></div>
 <div><Link to="/equipo_recubrimiento21"><img src={EquipoRecubrimiento21}></img><br/>Pistola automática<br/> AG361E</Link></div>
 <div><Link to="/equipo_recubrimiento22"><img src={EquipoRecubrimiento22}></img><br/>Pistola automática <br/>AGMDPRO</Link></div>
 <div><Link to="/equipo_recubrimiento23"><img src={EquipoRecubrimiento23}></img><br/>Pistola de pulverización<br/> por gravedad PROLite</Link></div>
 <div><Link to="/equipo_recubrimiento24"><img src={EquipoRecubrimiento24}></img><br/>Pistola manual <br/>Compact</Link></div>
 <div><Link to="/equipo_recubrimiento25"><img src={EquipoRecubrimiento25}></img><br/>Pistola manual <br/>Trophy</Link></div>
 <div><Link to="/equipo_recubrimiento26"><img src={EquipoRecubrimiento26}></img><br/>Pistola rociadora DV1 <br/>(CLEARCOAT, BASECOAT) Devilbiss</Link></div>
 <div><Link to="/equipo_recubrimiento27"><img src={EquipoRecubrimiento27}></img><br/>Pistolas de pulverización manuales<br/> sin aire asistidas por aire<br/> TROPHY 1600-4400 AIRLESS</Link></div>
 <div><Link to="/equipo_recubrimiento28"><img src={EquipoRecubrimiento28}></img><br/>Spray Booth<br/><br/><br/></Link></div>
 <div><Link to="/equipo_recubrimiento29"><img src={EquipoRecubrimiento29}></img><br/>Bomba de diafragma<br/> Graco DF3311</Link></div>
 <div><Link to="/equipo_recubrimiento30"><img src={EquipoRecubrimiento30}></img><br/>Tanque de presión <br/>Binks Serie 183G</Link></div>
 <div><Link to="/equipo_recubrimiento31"><img src={EquipoRecubrimiento31}></img><br/>Tanque de presión<br/> Binks Serie 183S</Link></div>
    
    
    </div>
  )
}

export default EquiposRecubrimiento