import React from 'react'
/* import punta1 from "../img/productos/puntas/1.-Punta Apex 1950-1x.jpg"
import punta2 from "../img/productos/puntas/2.-Punta Apex 320-px.jpg"
import punta3 from "../img/productos/puntas/3.-Punta Apex 440-2-acr2-rx.jpg"
import punta4 from "../img/productos/puntas/4.-Punta Apex 440-2r.jpg"
import punta5 from "../img/productos/puntas/5.-Punta Apex 440-2x.jpg"
import punta6 from "../img/productos/puntas/6.-Punta Apex 446-2x.jpg"
import punta7 from "../img/productos/puntas/7.-Punta Apex 480-4x.jpg"
import punta8 from "../img/productos/puntas/8.-Punta Apex 49-tx-10.jpg"
import punta9 from "../img/productos/puntas/9.-Punta Apex 491-ax.jpg"
import punta10 from "../img/productos/puntas/10.-Punta Apex 491-pzdx.jpg"
import punta11 from "../img/productos/puntas/11.-Punta Apex 491x.jpg"
import punta12 from "../img/productos/puntas/12.-Punta Apex 492-ax.jpg"
import punta13 from "../img/productos/puntas/13.-Punta Apex 493x.jpg"
import punta14 from "../img/productos/puntas/14.-Punta Apex AM-03.jpg"
import punta15 from "../img/productos/puntas/15.-Punta Apex Am-04.jpg"
import punta16 from "../img/productos/puntas/16.-Punta magnetica M-490-OR.jpg"
import punta17 from "../img/productos/puntas/17.-Punta Phillips Apex 492-bx.jpg"
import punta18 from "../img/productos/puntas/18.-Punta Phillips Apex 492-cx.jpg"
import punta19 from "../img/productos/puntas/19.-Punta Torx 49-tx-06.jpg"
import punta20 from "../img/productos/puntas/20.-Punta Torx Apex 440-tx-15I.jpg"
import punta21 from "../img/productos/puntas/21.-Punta Torx Apex 440-tx-15x.jpg"
import punta22 from "../img/productos/puntas/22.-Punta Torx Apex 440-tx-20x.jpg"
import punta23 from "../img/productos/puntas/23.-Punta Torx Apex 440-tx-25x.jpg"
import punta24 from "../img/productos/puntas/24.-Punta Torx Apex 480-tx-50x.jpg"
import punta25 from "../img/productos/puntas/25.-Punta Torx Apex 49-b-tx-10.jpg"
import punta26 from "../img/productos/puntas/26.-Punta Torx Apex 49-tx-20.jpg"
import punta27 from "../img/productos/puntas/27.-Punta Torx Apex 49-tx-20.jpg"
import punta28 from "../img/productos/puntas/28.-Punta Torx Apex 49-tx-25.jpg"
import punta29 from "../img/productos/puntas/29.-Punta Torx Apex 854-tx-50.jpg" */
import {Link} from "react-router-dom"
import './puntas.css'

function Puntas() {
  return (
    <div className='puntas'>
{/*     <div><Link to="/punta1Desc"><img src={punta1}></img></Link></div>
    <div><Link to="/punta2Desc"><img src={punta2}></img></Link></div>
    <div><Link to="/punta3Desc"><img src={punta3}></img></Link></div>
    <div><Link to="/punta4Desc"><img src={punta4}></img></Link></div>
    <div><Link to="/punta5Desc"><img src={punta5}></img></Link></div>
    <div><Link to="/punta6Desc"><img src={punta6}></img></Link></div>
    <div><Link to="/punta7Desc"><img src={punta7}></img></Link></div>
    <div><Link to="/punta8Desc"><img src={punta8}></img></Link></div>
    <div><Link to="/punta9Desc"><img src={punta9}></img></Link></div>
    <div><Link to="/punta10Desc"><img src={punta10}></img></Link></div>
    <div><Link to="/punta11Desc"><img src={punta11}></img></Link></div>
    <div><Link to="/punta12Desc"><img src={punta12}></img></Link></div>
    <div><Link to="/punta13Desc"><img src={punta13}></img></Link></div>
    <div><Link to="/punta14Desc"><img src={punta14}></img></Link></div>
    <div><Link to="/punta15Desc"><img src={punta15}></img></Link></div>
    <div><Link to="/punta16Desc"><img src={punta16}></img></Link></div>
    <div><Link to="/punta17Desc"><img src={punta17}></img></Link></div>
    <div><Link to="/punta18Desc"><img src={punta18}></img></Link></div>
    <div><Link to="/punta19Desc"><img src={punta19}></img></Link></div>
    <div><Link to="/punta20Desc"><img src={punta20}></img></Link></div>
    <div><Link to="/punta21Desc"><img src={punta21}></img></Link></div>
    <div><Link to="/punta21Desc"><img src={punta22}></img></Link></div>
    <div><Link to="/punta23Desc"><img src={punta23}></img></Link></div>
    <div><Link to="/punta24Desc"><img src={punta24}></img></Link></div>
    <div><Link to="/punta25Desc"><img src={punta25}></img></Link></div>
    <div><Link to="/punta26Desc"><img src={punta26}></img></Link></div>
    <div><Link to="/punta27Desc"><img src={punta27}></img></Link></div>
    <div><Link to="/punta28Desc"><img src={punta28}></img></Link></div>
    <div><Link to="/punta29Desc"><img src={punta29}></img></Link></div> */}
    </div>
  )
}

export default Puntas