import React from 'react'
import accesorio1 from "../img/productos/referenciaFusibles/1.-Fusible miniatura suplementario Clase Midget serie KLK.jpg"
import accesorio2 from "../img/productos/referenciaFusibles/2.-Fusible miniatura Clase Midget serie FLQ.jpg"
import accesorio3 from "../img/productos/referenciaFusibles/3.-Fusible miniatura Clase Midget serie BLF.jpg"
import accesorio4 from "../img/productos/referenciaFusibles/4.-Fusible miniatura Clase Midget serie FLM.jpg"
import accesorio5 from "../img/productos/referenciaFusibles/5.-Fusible Clase T serie JLLS.jpg"
import accesorio6 from "../img/productos/referenciaFusibles/6.-Fusible Clase K5 serie NLN.jpg"
import accesorio7 from "../img/productos/referenciaFusibles/7.-Fusible Clase J Serie JTD.jpg"
import accesorio8 from "../img/productos/referenciaFusibles/8.-Fusible Clase J serie JLS.jpg"
import accesorio9 from "../img/productos/referenciaFusibles/9.-Fusible miniatura  Clase G serie SLC.jpg"
import accesorio10 from "../img/productos/referenciaFusibles/10.-Fusible Clase CC serie KLKR.jpg"
import accesorio11 from "../img/productos/referenciaFusibles/11.-Fusible Clase CC serie CCMR.jpg"
import accesorio12 from "../img/productos/referenciaFusibles/12.-Fusible Clase CC serie KLDR.jpg"
import accesorio13 from "../img/productos/referenciaFusibles/13.-Fusible Clase RK5 serie FLNR.jpg"
import accesorio14 from "../img/productos/referenciaFusibles/14.-Fusible Clase RK5 serie FLSR.jpg"
import accesorio15 from "../img/productos/referenciaFusibles/15.-Fusible Clase SPF.jpg"
import accesorio16 from "../img/productos/referenciaFusibles/16.-Bloque de Portafusible L600M.jpg"
import accesorio17 from "../img/productos/referenciaFusibles/17.-Bloque de Portafusible LFJ.jpg"
import accesorio18 from "../img/productos/referenciaFusibles/18.-Bloque de Portafusible LFG.jpg"
import accesorio19 from "../img/productos/referenciaFusibles/19.-Bloque de Portafusible LFT.jpg"
import accesorio20 from "../img/productos/referenciaFusibles/20.-Bloque de Portafusible L600C.jpg"
import accesorio21 from "../img/productos/referenciaFusibles/21.-Bloque de Portafusible LPHV.jpg"
import accesorio22 from "../img/productos/referenciaFusibles/22.-Bloque de Portafusible LFJ.jpg"
import './referenciaFusibles.css'

function ReferenciaFusibles() {
  return (
    <div className='referenciaFusibles'>
    <div><img src={accesorio1}></img></div>
    <div><img src={accesorio2}></img></div>
    <div><img src={accesorio3}></img></div>
    <div><img src={accesorio4}></img></div>
    <div><img src={accesorio5}></img></div>
    <div><img src={accesorio6}></img></div>
    <div><img src={accesorio7}></img></div>
    <div><img src={accesorio8}></img></div>
    <div><img src={accesorio9}></img></div>
    <div><img src={accesorio10}></img></div>
    <div><img src={accesorio11}></img></div>
    <div><img src={accesorio12}></img></div>
    <div><img src={accesorio13}></img></div>
    <div><img src={accesorio14}></img></div>
    <div><img src={accesorio15}></img></div>
    <div><img src={accesorio16}></img></div>
    <div><img src={accesorio17}></img></div>
    <div><img src={accesorio18}></img></div>
    <div><img src={accesorio19}></img></div>
    <div><img src={accesorio20}></img></div>
    <div><img src={accesorio21}></img></div>
    <div><img src={accesorio22}></img></div>
    </div>
  )
}

export default ReferenciaFusibles