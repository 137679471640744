import React from 'react'
import remachadora1 from "../img/productos/remachadoras/1.-Remachadora de bateria RIV-750.jpg"
import remachadora2 from "../img/productos/remachadoras/2.-Lijadora angular extendida M2L025RS10.jpg"
import remachadora3 from "../img/productos/remachadoras/3.-Remachadora hidroneumatica RIV-502.jpg"
import remachadora4 from "../img/productos/remachadoras/4.-Remachadora hidroneumatica RIV-503.jpg"
import remachadora5 from "../img/productos/remachadoras/5.-Remachadora hidroneumatica RIV-505.jpg"
import remachadora6 from "../img/productos/remachadoras/6.-Remachadora hidroneumatica RIV-536.jpg"
import remachadora7 from "../img/productos/remachadoras/7.-Remachadora manual RIV901C.jpg"
import remachadora8 from "../img/productos/remachadoras/7.-Remachadora manual RIV901C.jpg"
import remachadora9 from "../img/productos/remachadoras/7.-Remachadora manual RIV901C.jpg"
import remachadora10 from "../img/productos/remachadoras/7.-Remachadora manual RIV901C.jpg"
import remachadora11 from "../img/productos/remachadoras/7.-Remachadora manual RIV901C.jpg"
import remachadora12 from "../img/productos/remachadoras/7.-Remachadora manual RIV901C.jpg"
import './remachadoras.css'

function Remachadoras() {
  return (
    <div className='remachadoras'>
    <div><img src={remachadora1}></img></div>
    <div><img src={remachadora2}></img></div>
    <div><img src={remachadora3}></img></div>
    <div><img src={remachadora4}></img></div>
    <div><img src={remachadora5}></img></div>
    <div><img src={remachadora6}></img></div>
    <div><img src={remachadora7}></img></div>
    <div><img src={remachadora8}></img></div>
    <div><img src={remachadora9}></img></div>
    <div><img src={remachadora10}></img></div>
    <div><img src={remachadora11}></img></div>
    <div><img src={remachadora12}></img></div>
    </div>
  )
}

export default Remachadoras